import { createApp } from 'vue'
import App from './App.vue'
import './assets/common.scss'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import router from "./router/index"
// import "amfe-flexible"
// import './utils/rem.js'


const app = createApp(App)
app.use(ElementPlus)// App.use(ElementPlus, { locale })
app.use(router)
app.mount('#app')
