// 首页
<template>
  <div class="home_wrap">
    <!-- 内容 -->
    <div class="home_cont">
      <!-- 带图片的内容模块 -->
      <div class="cont_img">
        <el-carousel
          class="new_wrap curPointer"
          height="510px"
          indicator-position="outside" v-if="imgNews!=false"
        >
          <el-carousel-item v-for="(item, index) in imgNews" :key="index" @click="detailClick(item.articleId,item.categoryId,item.categoryName)">
            <el-image class="new_img" :src="item.coverImage?item.coverImage:swiperBanner" fit="cover"></el-image>
            <h3 class="new_title" justify="center">{{ item.title }}</h3>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          class="new_wrap"
          height="510px"
          indicator-position="outside" v-else
        >
          <el-carousel-item>
            <el-image class="new_img" :src="swiperBanner" fit="cover"></el-image>
            <h3 class="new_title" justify="center">法制资讯聚焦网</h3>
          </el-carousel-item>
        </el-carousel>
        <div class="new_list_cont">
          <el-tabs
            v-model="contFirst"
            class="cont_text"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="(item, index) in constList24"
              :key="index"
              :label="item.categoryName"
              :name="item.categoryName"
            >
              <div
                v-if="
                  constList24.length > 3 &&
                  ((index == 0 && constList24[index].categoryId == 24) ||
                    (index == 1 && constList24[index].categoryId == 26) ||
                    (index == 2 && constList24[index].categoryId == 27) ||
                    (index == 3 && constList24[index].categoryId == 28))
                "
              >
                <div v-if="constList24[index].articleList != false">
                  <p
                    @click="detailClick(constList24[index].articleList[indexs].articleId,constList24[index].categoryId,constList24[index].categoryName)"
                    class="newsList ellipsis"
                    v-for="(items, indexs) in constList24[index].articleList"
                    :key="indexs"
                  >
                    {{ items.title }}
                  </p>
                </div>
                <div v-else><p class="home_empty">暂无数据</p></div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <p
            @click="listClick(1)"
            class="head_more tabs_more curPointer"
          >
            更多 ＞
          </p>
        </div>
      </div>
      <!-- 专题报道 法律援助 -->
      <div class="cont_report">
        <div
          class="report_cont"
          v-for="(itemA, indexA) in constList29"
          :key="indexA"
        >
          <div class="cont_head">
            <p class="head_p">{{ itemA.categoryName }}</p>
            <span @click="listClick(2,itemA.categoryId,itemA.categoryName)" class="head_span curPointer"
              >更多 ＞</span
            >
          </div>
          <div
            v-if="
              constList29.length > 2 &&
              ((indexA == 0 && constList29[indexA].categoryId == 29) ||
                (indexA == 1 && constList29[indexA].categoryId == 30) ||
                (indexA == 2 && constList29[indexA].categoryId == 31))
            "
          >
            <div v-if="constList29[indexA].articleList != false">
              <div
                @click="detailClick(constList29[indexA].articleList[index].articleId,constList29[indexA].categoryId,constList29[indexA].categoryName)"
                class="cont_list"
                v-for="(item, index) in constList29[indexA].articleList"
                :key="index"
              >
                <a class="list_img" href="" v-if="index == 0">
                  <el-image class="img" :src="item.coverImage!=false?item.coverImage:report[indexA]" fit="cover"></el-image>
                  <div class="img_right">
                    <p class="right_title ellipsis2">{{ item.title }}</p>
                    <p class="right_desic ellipsis3">{{ item.description }}</p>
                  </div>
                </a>
                <a
                  v-else-if="index < 6"
                  class="newsList ellipsis"
                  :href="item.url"
                  >{{ item.title }}</a
                >
              </div>
            </div>
            <div v-else><p class="home_empty">暂无数据</p></div>
          </div>
        </div>
      </div>
      <!-- 广告 -->
      <!-- <img class="ad_img" src="@/assets/imgs/ad.jpg" alt="" /> -->
      <el-image class="ad_img"
                :src="imgadTop"
                fit="cover"
              ></el-image>
      <!-- 反腐倡廉 -->
      <el-row class="cont_corrup">
        <el-col
          :span="12"
          class="corrup_item"
          v-for="(items, indexs) in constList32"
          :key="indexs"
        >
          <el-card shadow="never" :body-style="{ padding: '0px' }">
            <p class="corr_head">{{ items.categoryName}}</p>
            <el-image class="curPointer"
              style="width: 100%; height: 260px"
              :src="constList32.length > 3 &&constList32[indexs].articleList != false&&constList32[indexs].articleList[0].coverImage!=false?constList32[indexs].articleList[0].coverImage:artBanner[indexs]"
              fit="cover"  @click="listClick(2,items.categoryId,items.categoryName)"
            ></el-image>
            <div class="corr_list">
              <div
                v-if="
                  constList32.length > 3 &&
                  ((indexs == 0 && constList32[indexs].categoryId == 32) ||
                    (indexs == 1 && constList32[indexs].categoryId == 33) ||
                    (indexs == 2 && constList32[indexs].categoryId == 34) ||
                    (indexs == 3 && constList32[indexs].categoryId == 35))
                "
              >
              <div v-if="constList32[indexs].articleList != false">
                <p
                  class="list_a ellipsis"
                  v-for="(item, index) in constList32[indexs].articleList"
                  :key="index"
                  @click="detailClick(constList32[indexs].articleList[index].articleId,constList32[indexs].categoryId,constList32[indexs].categoryName)"
                >
                  {{ item.title }}
                </p></div>
              <div v-else><p class="home_empty">暂无数据</p></div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 广告 -->
      <el-image class="ad_img"
                :src="imgadMiddle"
                fit="cover"
              ></el-image>
      <!-- 生态环保 -->
      <div class="cont_eco">
        <!-- 左侧 -->
        <div class="eco_left">
          <div class="left_cont" v-for="(item, index) in constList36" :key="index">
            <div class="eco_head">
              <p class="head_title">{{ item.categoryName }}</p>
              <p @click="listClick(2,item.categoryId,item.categoryName)" class="head_more curPointer">更多 ＞</p>
            </div>
            <div class="eco_list">
              <div
                v-if="
                  constList36.length > 1 &&
                  ((index == 0 && constList36[index].categoryId == 36) ||
                    (index == 1 && constList36[index].categoryId == 38) )
                "
              >
              <div v-if="constList36[index].articleList != false">
              <p
                v-for="(items, indexs) in constList36[index].articleList"
                :key="indexs"
                class="newsList ellipsis"
                @click="detailClick(constList36[index].articleList[indexs].articleId,constList36[index].categoryId,constList36[index].categoryName)"
              >
                {{ items.title }}
              </p></div>
              <div v-else><p class="home_empty">暂无数据</p></div>
            </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="eco_right" v-if="constList37!=false">
          <div class="eco_head">
            <p class="head_title">{{constList37.categoryName}}</p>
            <p @click="listClick(2,37,constList37.categoryName)" class="head_more curPointer">更多 ＞</p>
          </div>
          <div class="eco_list">
            <div class="list_detail curPointer" @click="detailClick(constList37Top.articleId,37,constList37.categoryName)">
              <el-image
                style="width: 380px; height: 300px"
                :src="constList37Top.coverImage!=false?constList37Top.coverImage:personBanner"
                fit="cover"
              ></el-image>
              <div class="detail_cont">
                <p class="cont_title ellipsis2">
                  {{constList37Top.title}}
                </p>
                <p class="cont_desic ellipsis3" v-html="constList37Top.description">
                </p>
              </div>
            </div>
            <div class="right_list">
              <p
                @click="detailClick(constList37.articleList[indexs].articleId,37,constList37.categoryName)"
                v-for="(items, indexs) in constList37.articleList"
                :key="indexs"
                class="newsList ellipsis"
              >
                <span>{{ items.title }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告 -->
      <!-- <img class="ad_img" src="@/assets/imgs/ad.jpg" alt="" /> -->
      <el-image class="ad_img"
                :src="imgadBottom"
                fit="cover"
              ></el-image>
      <!-- 关注民生 -->
      <div class="cont_people">
        <el-tabs v-model="peopleName" @tab-click="handleTwoClick">
          <el-tab-pane
            v-for="(item, index) in constList39"
            :key="index"
            :label="item.categoryName"
            :name="item.categoryName"
          >
            <div
              class="list_wrap"
              v-if="
                constList39.length > 4 &&
                ((index == 0 && constList39[index].categoryId == 39) ||
                  (index == 1 && constList39[index].categoryId == 40) ||
                  (index == 2 && constList39[index].categoryId == 41) ||
                  (index == 3 && constList39[index].categoryId == 42) ||
                  (index == 4 && constList39[index].categoryId == 43))
              "
            >
              <div v-if="constList39[index].articleList != false" style="width:1200px;">
                <p
                  v-for="(items, indexs) in constList39[index].articleList"
                  :key="indexs"
                  @click="detailClick(constList39[index].articleList[indexs].articleId,constList39[index].categoryId,constList39[index].categoryName)"
                  class="newsList ellipsis"
                >
                  {{ items.title }}
                </p>
              </div>
              <div v-else><p class="home_empty">暂无数据</p></div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <p
          @click="listClick(3)"
          class="head_more tabs_more curPointer"
          :to="{ path: '/list', query: {} }"
        >
          更多 ＞
        </p>
      </div>
      <!-- 工作人员查询 -->
      <div class="cont_staff">
        <p class="staff_title">工作人员查询</p>
        <div class="staff_screen">
          <span class="screen_title"> 姓名</span>
          <el-input
            class="screen_ipt"
            v-model="lawyerData.staffName" onkeyup="this.value=this.value.replace(/\s*/g,'')"
            placeholder="请输入查询姓名"
          ></el-input>
        </div>
        <div class="staff_screen">
          <span class="screen_title">编号</span>
          <el-input
            class="screen_ipt"
            v-model="lawyerData.staffNum" onkeyup="this.value=this.value.replace(/\s*/g,'')"
            placeholder="请输入查询编号"
          ></el-input>
        </div>
        <el-button class="staff_btn" type="danger" round @click="screenClick()"
          >搜索</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { homeCont } from "@/api/home";
export default {
  data() {
    return {
      // banner图
      imgBanner: require("@/assets/imgs/banner.png"),
      swiperBanner: require("@/assets/imgs/swiperBanner.jpg"),
      imgadTop:require('@/assets/imgs/ad_top.jpg'),
      imgadMiddle:require('@/assets/imgs/ad_middle.jpg'),
      imgadBottom:require('@/assets/imgs/ad_bottom.jpg'),
      artBanner:[
        require('@/assets/imgs/anti_01.jpg'),
        require('@/assets/imgs/anti_02.jpg'),
        require('@/assets/imgs/anti_03.jpg'),
        require('@/assets/imgs/anti_04.jpg'),
      ],
      personBanner:require('@/assets/imgs/personBanner.jpg'),
      report:[require('@/assets/imgs/report.jpg'),
      require('@/assets/imgs/report_02.jpg'),
      require('@/assets/imgs/report_03.jpg')],

      imgNews: [
        { path: "", url: "", title: "图片1" },
        { path: "", url: "", title: "图片2" },
      ],
      contFirst: "综合资讯",
      
      peopleName: "关注民生",
      constList24: [],
      constList29: [],
      constList32: [],
      constList36: [],
      constList37Top: [],
      constList37: [],
      constList39: [], 
      tabOne:0,//tab当前选中索引 综合资讯
      tabTwo:0,//tab当前选中索引 关注民生

      lawyerData :{
        staffName:'',//名字
        staffNum:'',//编号
      }
    };
  },
  created() {
    this.homeContF();
  },
  methods: {
    // tab切换方法
    handleClick(tab, event) {
        console.log(tab.index);
        this.tabOne = tab.index;
      },
    handleTwoClick(tab, event) {
        console.log(tab.index);
        this.tabTwo = tab.index;
      },
    // 获取数据
    homeContF() {
      let data = {
        pageNum:1,
        pageSize:11
      }
      homeCont(data).then((res) => {
        if (res.code == 0) {
          let result = res.data;
          console.log("res", res);
          result.forEach((item, index) => {
            // 24	综合资讯 25	公示公告 26	群众来信 27	法制在线 28	时事评述  29	专题报道30	舆论监督 31	法律援助   32反腐倡廉 33	政策法规 34	司法动态 35	社会聚焦   36	生态环保 38	法制调查   37	人物访谈   39	关注民生 40	律师 41	曝光台 42	文化娱乐 43	名人诗书
            if(item.categoryId == 25||item.categoryId == 32 ||
              item.categoryId == 33 ||
              item.categoryId == 34 ||
              item.categoryId == 35||item.categoryId == 36||item.categoryId == 38){
                if(item.articleList!=false){
                  item.articleList = item.articleList.splice(0,5)
                }
            }
            if(item.categoryId == 24 ||
              item.categoryId == 26 ||
              item.categoryId == 27 ||
              item.categoryId == 28||item.categoryId == 39 ||
              item.categoryId == 40 ||
              item.categoryId == 41 ||
              item.categoryId == 42 ||
              item.categoryId == 43){
                if(item.articleList!=false){
                  item.articleList = item.articleList.splice(0,10)
                }
            }
            if (
              item.categoryId == 24 ||
              item.categoryId == 26 ||
              item.categoryId == 27 ||
              item.categoryId == 28
            ) {
              this.constList24.push(item);
            } else if (item.categoryId == 25) {
              this.constList25 = item.articleList;
            } else if (
              item.categoryId == 29 ||
              item.categoryId == 30 ||
              item.categoryId == 31
            ) {
              if(item.articleList!=false){
                  item.articleList = item.articleList.splice(0,6)
                }
              this.constList29.push(item);
            } else if (
              item.categoryId == 32 ||
              item.categoryId == 33 ||
              item.categoryId == 34 ||
              item.categoryId == 35
            ) {
              this.constList32.push(item);
            } else if (item.categoryId == 36||item.categoryId == 38) {
              this.constList36.push(item);
            } else if (item.categoryId == 37) {
              this.constList37Top = item.articleList[0];
              if(item.articleList!=false){
                item.articleList = item.articleList.slice(1,11)
              }
              this.constList37 = item;
            }else if (
              item.categoryId == 39 ||
              item.categoryId == 40 ||
              item.categoryId == 41 ||
              item.categoryId == 42 ||
              item.categoryId == 43
            ) {
              this.constList39.push(item);
            }else if (item.categoryId=="indexFocus"){
              this.imgNews = item.articleList;
            }
          });
          
              console.log('this.constList39',this.constList39)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 列表页跳转
    listClick(type,id,name) {
      let routeUrl = null;
      if(type==1){
        routeUrl = this.$router.resolve({ path: "/list", query: { id: this.constList24[this.tabOne].categoryId,name:this.constList24[this.tabOne].categoryName } });
      }else if(type==3){
        routeUrl = this.$router.resolve({ path: "/list", query: { id: this.constList39[this.tabTwo].categoryId,name:this.constList39[this.tabTwo].categoryName } });
      }else{
        routeUrl = this.$router.resolve({ path: "/list", query: { id: id,name:name } });
      }
      
     window.open(routeUrl.href,'_blank')
    },
    // 详情页跳转
    detailClick(id,listId,listName) {
     const routeUrl = this.$router.resolve({ path: "/detail", query: { id: id,listId:listId,listName:listName } });
     window.open(routeUrl.href,'_blank')
    },
    // 律师搜索
    screenClick() {
      let data = this.lawyerData;
      if(data.staffName==''||data.staffNum==''){
        this.$message({
            type: 'info',
            message: '请输入律师姓名和编号'
          });
        return
      }
      this.$router.push({ path: "/screen", query: data });
    },
  },
};
</script>
<style lang="scss" scoped>
// 公共样式
@mixin disCenter {
  display: flex;
  justify-content: space-between;
}
.head_more {
  font-size: 12px;
  color: rgb(136, 136, 136);
}
.tabs_more {
  position: absolute;
  top: 13px;
  right: 0;
}
// 广告
.ad_img {
  margin-bottom: 20px;
  width: 1200px;
}
// 公共样式结束
.home_wrap {
  width: 100%;
  margin-top: 0;
  .home_cont {
    width: 1200px;
    margin: 10px auto 0;
    .home_empty {
      text-align: center;
      width: 100%;
      line-height: 100px;
    }
    // 图片模块
    .cont_img {
      @include disCenter;
      .newsList {
        &:first-child {
          margin-top: -10px;
        }
      }
      .new_list_cont {
        position: relative;
      }
      .new_wrap {
        position: relative;
        width: 710px;
        height: 510px;
        overflow: hidden;
        .new_img {
          width: 100%;
          height: 100%;
          background: cyan;
        }
        .new_title {
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 14px;
          color: #fff;
          display: inline-block;
          background: rgba(0, 0, 0, 0.5);
          width: 690px;
          height: 60px;
          line-height: 60px;
          padding-left: 20px;
          margin-bottom: 0;
        }
      }
    }
    .cont_text {
      width: 470px;
      .el-tabs__header .el-tabs__nav-wrap .el-tabs__item {
        color: pink !important;
      }
    }

    .newsList {
      position: relative;
      padding-left: 14px;
      cursor: pointer;
      color: #333;
      line-height: 48px;
      display: block;
      width: 98%;
      &:hover {
        color: #bd1a2d;
        
        &:after{
          background:#bd1a2d;
        }
      }
      &:after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: #dddddd;
        position: absolute;
        left: 0;
        top: 22px;
      }
    }
    // 专题报道
    .cont_report {
      @include disCenter;
      .report_cont {
        width: 380px;
        .cont_head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f0f0f0;
          height: 58px;
          line-height: 58px;
          .head_p {
            position: relative;
            padding-left: 16px;
            font-size: 18px;
            font-weight: 600;
            &:after {
              position: absolute;
              content: "";
              width: 4px;
              height: 16px;
              background: #bd1a2d;
              top: 20px;
              left: 0px;
            }
          }
          .head_span {
            font-size: 12px;
            color: rgb(136, 136, 136);
          }
        }
        .cont_list {
          font-size:14px;
          .list_img {
            @include disCenter;
            .img {
              width: 190px;
              height: 115px;
              margin-right: 20px;
            }
            .img_right {
              line-height: 26px;width:168px;
              .right_title {
                font-size: 14px;
                color: #333;
                font-weight: 600;
                margin-top: -4px;
              }
              .right_desic {
                font-size: 12px;
                color: #666;
                line-height: 20px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    // 反腐倡廉
    .cont_corrup {
      .corrup_item {
        margin-bottom: 20px;
        padding: 0 8px;
        .corr_head {
          text-align: center;
          position: relative;
          font-size: 18px;
          font-weight: 600;
          padding: 10px 0;
          &:after {
            position: absolute;
            content: "";
            width: 110px;
            height: 4px;
            background: #bd1a2d;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
        .corr_list {
          min-height:230px;
          .list_a {
            padding-left: 14px;
            cursor: pointer;
            font-size: 14px;
            line-height: 46px;
            &:hover {
              color: #bd1a2d;
            }
          }
        }
      }
    }
    // 生态环保
    .cont_eco {
      @include disCenter;

      .eco_head {
        @include disCenter;
        border-bottom: 2px solid #bd1a2d;
        align-items: center;
        margin-bottom: 10px;
        .head_title {
          color: #fff;
          position: relative;
          padding: 0 0 0 24px;
          height: 48px;
          z-index: 3;
          line-height: 48px;
          font-size: 18px;
          font-weight: 600;
          &:after {
            content: "";
            width: 120px;
            height: 48px;
            background: #bd1a2d;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }
        }
      }
      .eco_left {
        width: 400px;
        .left_cont {
        }
      }
      .eco_right {
        width: 778px;
        .eco_head {
          margin-bottom: 0;
        }
        .list_detail {
          @include disCenter;
          height: 300px;
          .detail_cont {
            width: 370px;
            .cont_title {
              font-size: 16px;
              font-weight: 600;
              color: #333;
              line-height: 30px;
              margin-top: 8px;
            }
            .cont_desic {
              font-size: 14px;
              color: #666;
              line-height: 26px;
              margin-top: 6px;
              -webkit-line-clamp: 9;
              text-indent:20px;
            }
          }
        }
        .right_list {
          @include disCenter;
          flex-wrap: wrap;
          margin-top: 10px;
          .newsList {
            width: 48%;
          }
        }
      }
    }
    // 关注民生
    .cont_people {
      position: relative;
      .list_wrap {
        @include disCenter;
        flex-wrap: wrap;
        .newsList {
          width: 48%;
          display: inline-block;
          &:nth-child(odd){
            margin-right:16px;
          }
        }
      }
    }
    // 工作人员查询
    .cont_staff {
      background: #f3f3f3;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      color: #333;
      margin: 20px 0;
      .staff_title {
        font-size: 16px;
        font-weight: 600;
        margin-right: 20px;
      }
      .staff_screen {
        @include disCenter;
        align-items: center;
        .screen_title {
          font-size: 14px;
        }
        .screen_ipt {
          width: 300px;
          margin: 0 50px 0 16px;
        }
      }
      .staff_btn {
        font-size: 14px;
        background: #bd1a2d;
        border-color: #bd1a2d;
        padding: 4px 30px;
      }
    }
  }
}
</style>