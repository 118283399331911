<template>
  <div class="content">
    <div class="head_icon"  v-if="imgType">
      <!-- <div class="cont_left">
        <img class="left_icon" src="@/assets/logo.png" alt="" />
        <div class="left_text curPointer" @click="redirectClick(0)">法制资讯聚焦</div>
      </div> -->
      
    <img class="home_banner" src="@/assets/imgs/banner.jpg" alt="" />
      <div class="cont_right">
        <div class="right_time">{{ time }}</div>
      </div>
    </div>
    <!-- <img v-if="imgType" class="home_banner" src="@/assets/imgs/banner.jpg" alt="" /> -->
    <div class="header_nav">
      <div class="nav_cont">
        <div class="nav_text" v-for="(item, index) in navList" :key="index" @click="redirectClick(index,item)">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showTime } from "@/api/public";
export default {
  data() {
    return {
      time: showTime(),
      // 24	综合资讯 25	公示公告 26	群众来信 27	法制在线 28	时事评述  29	专题报道30	舆论监督 31	法律援助   32反腐倡廉 33	政策法规 34	司法动态 35	社会聚焦   36	生态环保 38	法制调查   37	人物访谈   39	关注民生 40	律师 41	曝光台 42	文化娱乐 43	名人诗书
      navList: [
        { title: "网站首页", id: "/" },
        { title: "综合资讯", id: "24" },
        { title: "法治在线", id: "27" },
        { title: "政策法规", id: "33" },
        { title: "人物访谈", id: "37" },
        { title: "时事评述", id: "28" },
        { title: "舆论监督", id: "30" },
        { title: "法律援助", id: "31" },
        { title: "专题报道", id: "29" },
        { title: "关注民生", id: "39" },
        { title: "反腐倡廉", id: "32" },
      ],
      imgType:true,
    };
  },
  created() {
    // this.showTimeF()
  },
  methods: {
    redirectClick(index,item){
      let path = index ==0 ?'/':'/list';
      let id = index ==0?'':{id:item.id,name:item.title};
      this.$router.push({ path: path, query: id})
    },
    
  },
  watch:{
    $route(to, from) {
    // to.params为最新的参数
    // from.params为之前的参数
    console.log('to',to)
      this.imgType = to.path=='/'?true:false;
      // this.imgType = to.path=='/list'||to.path=='/detail'||to.path=='/screen'||to.path=='/about'||to.path=='/coopeation'||to.path=='/relation'?false:true;
      
    },
  }
};
</script>
<style lang="scss" scoped>

@mixin disCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  .home_banner {
    height: 515px;
    width: 100%;
    overflow-x: hidden;
  }
    .head_icon {
    width: 100%;
    height: 515px;
    background: #fff;
    margin: 0 auto;
    @include disCenter;
    position:relative;
  }
  .cont_left {
    @include disCenter;
    .left_icon {
      width: 55px;
      height: 55px;
    }
    .left_text {
      margin-left: 10px;
      font-size: 30px;
      color: #bd1a2d;
    }
  }
  .cont_right {
    font-size: 16px;font-weight:600;
    position:absolute;top:20px;right:50px;color:#fff;text-shadow:0 0 4px red;
  }
  .header_nav {
    height: 80px;
    line-height: 80px;
    background: #bd1a2d;

    .nav_cont {
      @include disCenter;
      width: 1200px;
      margin: 0 auto;
      .nav_text {
        cursor: pointer;
        display: inline-block;
        color: #fff;
        font-size: 18px;
        &:hover{
            font-size:19px;
        }
      }
    }
  }
}
</style>