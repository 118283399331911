import request from '@/api/request'

/*
*首页
*/
export function homeCont(data) {
  return request({
    url: '/blog/index',
    method: 'post',
    data
  })
}
/*
*搜索
*/
export function screenLawyer(data) {
  return request({
    url: '/blog/showStaff',
    method: 'post',
    data
  })
}

/*
*列表
*/
export function listCont(data) {
  return request({
    url: '/blog/list',
    method: 'post',
    data
  })
}

/*
*详情
*/
export function detailCont(data) {
  return request({
    url: '/blog/detail',
    method: 'post',
    data
  })
}