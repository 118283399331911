import axios from 'axios'
import { Message } from 'element-plus';
// const baseUrl = 'http://192.168.0.106:8085';
const baseUrl = '/api';
// const baseUrl = process.env.VUE_APP_BASE_API;


/**
 * 创建axios实例
 * @type {AxiosInstance}
 */
const service = axios.create({
  baseURL: baseUrl, // api的base_url
  timeout: 60000 // 设置请求超时时间5s
})
/**
 * 请求参数处理
 */
service.interceptors.request.use((config) => {
  console.log('congig',config)
  config.method === 'post'
    ? config.data = config.data// qs.stringify({...config.data})
    : config.params = { ...config.params }
  config.withCredentials = true
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded' // 后端 所需参数
  return config
}
)
/**
 * 响应结果处理
 */
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 0 || response.status === 200) {
      // 服务端定义的响应code码为0时请求成功
      // 使用Promise.resolve 正常响应
      if(response.data.status==20101){
        Message.error(response.data.msg)
        return Promise.reject(response.data)
      }else{
        return Promise.resolve(response.data)
      }
    } else {
      // 使用Promise.reject 响应
      Message.success(response.data.message)
      return Promise.reject(response.data)
    }
  }, error => {
    let message = ''
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          // location.reload()
          return
        case 403:
          message = error.response.data.path + ',' + error.response.data.message
          break
        case 502:
          message = '连接服务器失败'
          break
        default:
          message = error.response.data.message ? error.response.data.message : '服务器错误'
          break
      }
      Message.success(message)
      // 请求错误处理
      return Promise.reject(error)
    } else {
      message = '连接服务器失败'
      Message.success(message)
      return Promise.reject(error)
    }
  }
)

export default service
