<template>
  <div id="content" ref="app">
    <Head />
      <router-view />
    <Footer /> 
  </div>
</template>

<script>
import Home from './views/home.vue'
import Footer from './views/public/footer.vue'
import Head from './views/public/head.vue'
import _ from 'lodash'
export default {
  name: 'App',
  components: {
    Home,Footer,Head
  },
  mounted() {
    // this.$nextTick(() => {
    //   const $app = this.$refs.app;
    //   // 设置 屏幕 百分比 尺寸 适配
    //   const standardScale = "100%" / "100%";

    //   window.addEventListener(
    //     "resize",
    //     _.debounce(function () {
    //       const docHeight = document.body.clientHeight;
    //       const docWidth = document.body.clientWidth;

    //       if (docWidth < 1080) {
    //         const currentScale = docHeight / docWidth;
    //         let [scale, translate] = [0, 0];
    //         if (currentScale < standardScale) {
    //           // 以高度计算
    //           scale = docHeight / 1080;
    //           const shouleWidth = 1920 * scale;
         
    //           const offsetWidth = docWidth - shouleWidth;
    //           translate =
    //             offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : "";
    //         } else {
    //           // 以宽度计算
    //           scale = docWidth / 1920;
    //           const shouleHeight = 1080 * scale;
    //           const offsetHeight = docHeight - shouleHeight;
    //           translate =
    //             offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : "";
    //         }
    //         console.log(translate);
    //         $app.style.cssText = `
    //         transform: scale(${scale}) ${translate};
    //         transform-origin: top left;
    //         min-width: 1920px;
    //         min-height: 1080px;
    //       `;
    //       } else {
    //         $app.style.cssText = "";
    //       }
    //     }),
    //     300
    //   );

    //   if (document.createEvent) {
    //     var event = document.createEvent("HTMLEvents");
    //     event.initEvent("resize", true, true);
    //     window.dispatchEvent(event);
    //   } else if (document.createEventObject) {
    //     window.fireEvent("onresize");
    //   }
    // });
  },
  watch:{
    $route(to, from) {
    // to.params为最新的参数
    // from.params为之前的参数
    let phpStatus = to.path.includes('.php');
    if(phpStatus){
      this.$router.push({ path: "/", });
    }
      // this.imgType = to.path.includes('php')?true:false;
    },
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
