// import Vue from 'vue'
import {createRouter,createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/views/home'),
  },
    //404页面捕获
    {
      path: '/:catchAll(.*)',
      component: () => import('@/views/home')
  },
  {
    path: '/list',
    component: () => import('@/views/list'),
    hidden: true
  },
  {
    path: '/detail',
    component: () => import('@/views/detail'),
    hidden: true
  },
  {
    path: '/screen',
    component: () => import('@/views/screen'),
    hidden: true
  },
  {
    path: '/about',
    component: () => import('@/views/bottomCont/about'),
    hidden: true
  },
  {
    path: '/coopeation',
    component: () => import('@/views/bottomCont/coopeation'),
    hidden: true
  },
  {
    path: '/relation',
    component: () => import('@/views/bottomCont/relation'),
    hidden: true
  },

  // { path: '*', redirect: '/content/list', hidden: true }
]

const router = createRouter({
  history:createWebHistory(process.env.BASE_URL),
  routes
})
export default router

